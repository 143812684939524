import * as yup from 'yup';

export const schemaPedido = yup.object().shape({
    cod_loja: yup.number().required(),
    busca_parceiro: yup.object().shape({
        label: yup.string().required(),
        value: yup.number().required(),
    }),
    cod_perfil: yup.object().shape({
        label: yup.string().required(),
        value: yup.number().required(),
    }),
    vendedor: yup.object().shape({
        label: yup.string().required(),
        value: yup.number().required()
    }),
    dta_emissao: yup.string().required()
});


export const schemaProduto = yup.object().shape({
    qtd_pedido: yup.string().required(),
    val_pedido: yup.string().required()
})


export const schemaFinanceiro = yup.object().shape({
    finalizadora: yup.object().shape({
        cod_finalizadora: yup.number().required(),
        des_finalizadora: yup.string().required(),
        flg_inativa: yup.boolean().required(),
        label: yup.string().required(),
        value: yup.number().required(),
    }),
    dta_vencimento: yup.string().required(),
})